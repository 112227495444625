a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
    display: none;
}

#CookiebotWidget .CookiebotWidget-main-logo svg {
    display: none !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount{
    color: #ffffff !important;
}