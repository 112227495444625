* {
  scrollbar-width: thin;
  scrollbar-color: #64748b66 #64748b26;
}

::-webkit-scrollbar {
  background-color: #0000;
  width: .375rem !important;
}

::-webkit-scrollbar-track {
  background-color: #64748b26 !important;
  border-radius: .25rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: #64748b66 !important;
  border-radius: .25rem !important;
}

div.conv-list {
  scrollbar-color: #64748b00 #64748b00;
  scrollbar-width: none;
}

div.conv-list::-webkit-scrollbar {
  background-color: #0000;
  width: 1px !important;
}

div.conv-list::-webkit-scrollbar-track {
  background-color: #64748b00 !important;
}

div.conv-list::-webkit-scrollbar-thumb {
  background-color: #64748b00 !important;
}

input[type="range"] {
  appearance: none;
  cursor: pointer;
  background: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #cecaba;
  border-radius: 2px;
  height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: #cecaba;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: -13px;
  display: block;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: none;
  outline: none;
}

input[type="range"]::-moz-range-track {
  background-color: #cecaba;
  border-radius: 2px;
  height: 2px;
}

input[type="range"]::-moz-range-thumb {
  background-color: #cecaba;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #cecaba;
}

a#CybotCookiebotDialogPoweredbyCybot, div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget .CookiebotWidget-main-logo svg {
  display: none !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
  color: #fff !important;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

html {
  font-family: InterNormal, system-ui, sans-serif;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width >= 2560px) {
  .container {
    max-width: 2560px;
  }
}

@media (width >= 3840px) {
  .container {
    max-width: 3840px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.\!bottom-8 {
  bottom: 2rem !important;
}

.\!bottom-auto {
  bottom: auto !important;
}

.\!left-auto {
  left: auto !important;
}

.\!right-2 {
  right: .5rem !important;
}

.\!right-20 {
  right: 5rem !important;
}

.\!right-24 {
  right: 6rem !important;
}

.\!right-6 {
  right: 1.5rem !important;
}

.\!top-0 {
  top: 0 !important;
}

.\!top-\[140\%\] {
  top: 140% !important;
}

.\!top-\[70\%\] {
  top: 70% !important;
}

.-bottom-\[35px\] {
  bottom: -35px;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-10 {
  left: -2.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[-24px\] {
  bottom: -24px;
}

.bottom-\[15px\] {
  bottom: 15px;
}

.left-0 {
  left: 0;
}

.left-2\/4 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-6 {
  left: 1.5rem;
}

.left-\[-17px\] {
  left: -17px;
}

.left-\[0\.32px\] {
  left: .32px;
}

.left-\[0\] {
  left: 0;
}

.left-\[10px\] {
  left: 10px;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[12px\] {
  right: 12px;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-2\/4 {
  top: 50%;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-12px\] {
  top: -12px;
}

.top-\[12px\] {
  top: 12px;
}

.top-\[13rem\] {
  top: 13rem;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[16px\] {
  top: 16px;
}

.top-\[240px\] {
  top: 240px;
}

.top-\[25px\] {
  top: 25px;
}

.z-10 {
  z-index: 10;
}

.z-1000 {
  z-index: 1000;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.float-left {
  float: left;
}

.m-auto {
  margin: auto;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[-8px\] {
  margin-bottom: -8px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[22px\] {
  margin-bottom: 22px;
}

.mb-\[80px\] {
  margin-bottom: 80px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[0\] {
  margin-left: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[14px\] {
  margin-top: 14px;
}

.mt-\[235px\] {
  margin-top: 235px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.box-content {
  box-sizing: content-box;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-\[1px\] {
  height: 1px !important;
}

.\!h-\[24px\] {
  height: 24px !important;
}

.\!h-\[32px\] {
  height: 32px !important;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-16 {
  height: 4rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[0px\] {
  height: 0;
}

.h-\[100dvh\] {
  height: 100dvh;
}

.h-\[102px\] {
  height: 102px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[217px\] {
  height: 217px;
}

.h-\[235px\] {
  height: 235px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[420px\] {
  height: 420px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[61px\] {
  height: 61px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[76px\] {
  height: 76px;
}

.h-\[80px\] {
  height: 80px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[30px\] {
  max-height: 30px;
}

.max-h-\[325px\] {
  max-height: 325px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[600px\] {
  max-height: 600px;
}

.max-h-\[620px\] {
  max-height: 620px;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-\[222px\] {
  min-height: 222px;
}

.min-h-\[240px\] {
  min-height: 240px;
}

.min-h-\[345px\] {
  min-height: 345px;
}

.min-h-\[34px\] {
  min-height: 34px;
}

.min-h-\[420px\] {
  min-height: 420px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.min-h-\[50px\] {
  min-height: 50px;
}

.min-h-\[510px\] {
  min-height: 510px;
}

.min-h-\[545px\] {
  min-height: 545px;
}

.min-h-\[610px\] {
  min-height: 610px;
}

.min-h-\[calc\(100vh_-_62px\)\] {
  min-height: calc(100vh - 62px);
}

.min-h-\[calc\(100vh_-_62px_-_110px\)\] {
  min-height: calc(100vh - 172px);
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-\[24px\] {
  width: 24px !important;
}

.\!w-\[32px\] {
  width: 32px !important;
}

.w-10 {
  width: 2.5rem;
}

.w-3\/12 {
  width: 25%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[102px\] {
  width: 102px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[12px\] {
  width: 12px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[152px\] {
  width: 152px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[42px\] {
  width: 42px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[fit-content\] {
  width: fit-content;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[141px\] {
  min-width: 141px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[205px\] {
  min-width: 205px;
}

.min-w-\[260px\] {
  min-width: 260px;
}

.\!max-w-\[100\%\] {
  max-width: 100% !important;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.max-w-\[1100px\] {
  max-width: 1100px;
}

.max-w-\[1205px\] {
  max-width: 1205px;
}

.max-w-\[194px\] {
  max-width: 194px;
}

.max-w-\[205px\] {
  max-width: 205px;
}

.max-w-\[210px\] {
  max-width: 210px;
}

.max-w-\[215px\] {
  max-width: 215px;
}

.max-w-\[255px\] {
  max-width: 255px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[310px\] {
  max-width: 310px;
}

.max-w-\[340px\] {
  max-width: 340px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[370px\] {
  max-width: 370px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[420px\] {
  max-width: 420px;
}

.max-w-\[530px\] {
  max-width: 530px;
}

.max-w-\[560px\] {
  max-width: 560px;
}

.max-w-\[585px\] {
  max-width: 585px;
}

.max-w-\[590px\] {
  max-width: 590px;
}

.max-w-\[59rem\] {
  max-width: 59rem;
}

.max-w-\[60px\] {
  max-width: 60px;
}

.max-w-\[630px\] {
  max-width: 630px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[645px\] {
  max-width: 645px;
}

.max-w-\[650px\] {
  max-width: 650px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[750px\] {
  max-width: 750px;
}

.max-w-\[760px\] {
  max-width: 760px;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-\[85\%\] {
  max-width: 85%;
}

.max-w-\[870px\] {
  max-width: 870px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-\[90px\] {
  max-width: 90px;
}

.max-w-\[94\%\] {
  max-width: 94%;
}

.max-w-\[950px\] {
  max-width: 950px;
}

.max-w-\[970px\] {
  max-width: 970px;
}

.max-w-\[980px\] {
  max-width: 980px;
}

.max-w-\[985px\] {
  max-width: 985px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-none {
  max-width: none;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-4\/12 {
  flex-basis: 33.3333%;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.scroll-my-40 {
  scroll-margin-top: 10rem;
  scroll-margin-bottom: 10rem;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.scroll-mt-40 {
  scroll-margin-top: 10rem;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.\!justify-center {
  justify-content: center !important;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-\[20px\] {
  border-radius: 20px !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.rounded-\[40px\] {
  border-radius: 40px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-\[5px\] {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-b-\[8px\] {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-\[8px\] {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-\[16px\] {
  border-bottom-left-radius: 16px;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-\[13px\] {
  border-bottom-right-radius: 13px;
}

.rounded-br-\[16px\] {
  border-bottom-right-radius: 16px;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-\[13px\] {
  border-top-left-radius: 13px;
}

.rounded-tl-\[3px\] {
  border-top-left-radius: 3px;
}

.rounded-tl-\[4px\] {
  border-top-left-radius: 4px;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-tr-\[13px\] {
  border-top-right-radius: 13px;
}

.rounded-tr-\[3px\] {
  border-top-right-radius: 3px;
}

.rounded-tr-\[4px\] {
  border-top-right-radius: 4px;
}

.\!border-2 {
  border-width: 2px !important;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-\[0\.5px\] {
  border-width: .5px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-b, .border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-\[0\.5px\] {
  border-top-width: .5px;
}

.\!border-\[\#0C2632\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(12 38 50 / var(--tw-border-opacity, 1)) !important;
}

.\!border-\[\#CECABA\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(206 202 186 / var(--tw-border-opacity, 1)) !important;
}

.\!border-\[\#fff\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1)) !important;
}

.border-\[\#002733\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 39 51 / var(--tw-border-opacity, 1));
}

.border-\[\#0C2632\] {
  --tw-border-opacity: 1;
  border-color: rgb(12 38 50 / var(--tw-border-opacity, 1));
}

.border-\[\#43262D\] {
  --tw-border-opacity: 1;
  border-color: rgb(67 38 45 / var(--tw-border-opacity, 1));
}

.border-\[\#6F86A0\] {
  --tw-border-opacity: 1;
  border-color: rgb(111 134 160 / var(--tw-border-opacity, 1));
}

.border-\[\#A0C1AB\] {
  --tw-border-opacity: 1;
  border-color: rgb(160 193 171 / var(--tw-border-opacity, 1));
}

.border-\[\#CECABA\] {
  --tw-border-opacity: 1;
  border-color: rgb(206 202 186 / var(--tw-border-opacity, 1));
}

.border-\[\#CFCAB8\] {
  --tw-border-opacity: 1;
  border-color: rgb(207 202 184 / var(--tw-border-opacity, 1));
}

.border-\[\#E7E4D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 228 217 / var(--tw-border-opacity, 1));
}

.border-\[\#E9E3D0\] {
  --tw-border-opacity: 1;
  border-color: rgb(233 227 208 / var(--tw-border-opacity, 1));
}

.border-\[\#EA3324\] {
  --tw-border-opacity: 1;
  border-color: rgb(234 51 36 / var(--tw-border-opacity, 1));
}

.border-\[\#FFEEC1\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 238 193 / var(--tw-border-opacity, 1));
}

.border-\[\#FFFEFB\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 254 251 / var(--tw-border-opacity, 1));
}

.border-\[\#fff\], .border-\[\#ffffff\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-dark-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 39 51 / var(--tw-border-opacity, 1));
}

.border-deep-blue {
  --tw-border-opacity: 1;
  border-color: rgb(12 38 50 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-saturate-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(255 207 43 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-b-\[\#CFCAB8\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(207 202 184 / var(--tw-border-opacity, 1));
}

.border-b-\[\#FFFEFB\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 254 251 / var(--tw-border-opacity, 1));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-opacity-25 {
  --tw-border-opacity: .25;
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.\!bg-\[\#F7D152\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(247 209 82 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-deep-blue {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(12 38 50 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-\[\#0C2632\] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 50 / var(--tw-bg-opacity, 1));
}

.bg-\[\#0C2632\]\/10 {
  background-color: #0c26321a;
}

.bg-\[\#273F5C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 63 92 / var(--tw-bg-opacity, 1));
}

.bg-\[\#43262D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 38 45 / var(--tw-bg-opacity, 1));
}

.bg-\[\#5F3741\] {
  --tw-bg-opacity: 1;
  background-color: rgb(95 55 65 / var(--tw-bg-opacity, 1));
}

.bg-\[\#7DDE8D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(125 222 141 / var(--tw-bg-opacity, 1));
}

.bg-\[\#916564\] {
  --tw-bg-opacity: 1;
  background-color: rgb(145 101 100 / var(--tw-bg-opacity, 1));
}

.bg-\[\#CECABA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(206 202 186 / var(--tw-bg-opacity, 1));
}

.bg-\[\#D1D8E0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(209 216 224 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E8E3D2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 227 210 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E9E3D0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 227 208 / var(--tw-bg-opacity, 1));
}

.bg-\[\#EA3324\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 51 36 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F3F0E680\] {
  background-color: #f3f0e680;
}

.bg-\[\#F3F0E6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 240 230 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F9F7F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 241 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FEFAEE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 250 238 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFEFB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 254 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3f1e8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 241 232 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fff\], .bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 39 51 / var(--tw-bg-opacity, 1));
}

.bg-deep-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 50 / var(--tw-bg-opacity, 1));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}

.bg-neutral-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(243 240 230 / var(--tw-bg-opacity, 1));
}

.bg-saturate-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 207 43 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-campaign {
  background-image: url("letter_bg.940ab8a8.jpeg");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-main {
  background-image: url("bg-full.e02942fa.jpg");
}

.bg-quote {
  background-image: url("quote.aeed696f.svg");
}

.bg-team {
  background-image: url("team.97f943fc.jpg");
}

.from-\[\#0000008a\] {
  --tw-gradient-from: #0000008a var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-\[length\:120px_100px\] {
  background-size: 120px 100px;
}

.bg-cover {
  background-size: cover;
}

.bg-\[center_top\] {
  background-position: top;
}

.bg-\[top_left\] {
  background-position: 0 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-dark-blue {
  fill: #002733;
}

.fill-white {
  fill: #fff;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-\[center_65px\] {
  object-position: center 65px;
}

.object-center {
  object-position: center;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.\!p-\[4px\] {
  padding: 4px !important;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[15px\] {
  padding: 15px;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[40px\] {
  padding-top: 40px;
  padding-bottom: 40px;
}

.\!pb-28 {
  padding-bottom: 7rem !important;
}

.\!pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pb-\[18px\] {
  padding-bottom: 18px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-\[18px\] {
  padding-top: 18px;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.pt-\[58px\] {
  padding-top: 58px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.indent-5 {
  text-indent: 1.25rem;
}

.font-cormorantitalic {
  font-family: Cormorant-Italic, sans-serif;
}

.font-interblack {
  font-family: InterBlack, sans-serif;
}

.font-interbold {
  font-family: InterBold, sans-serif;
}

.font-interlight {
  font-family: InterLight, sans-serif;
}

.font-intermedium {
  font-family: InterMedium, sans-serif;
}

.font-internormal {
  font-family: InterNormal, sans-serif;
}

.\!text-\[14px\] {
  font-size: 14px !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[33px\] {
  font-size: 33px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-\[13px\] {
  font-weight: 13px;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[14\.40px\] {
  line-height: 14.4px;
}

.leading-\[15px\] {
  line-height: 15px;
}

.leading-\[2\.7rem\] {
  line-height: 2.7rem;
}

.leading-\[21px\] {
  line-height: 21px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-\[23px\] {
  line-height: 23px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[25px\] {
  line-height: 25px;
}

.leading-\[29px\] {
  line-height: 29px;
}

.leading-\[31px\] {
  line-height: 31px;
}

.leading-\[39\.60px\] {
  line-height: 39.6px;
}

.leading-\[39px\] {
  line-height: 39px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[\.12em\] {
  letter-spacing: .12em;
}

.tracking-\[\.25em\] {
  letter-spacing: .25em;
}

.tracking-\[\.2em\] {
  letter-spacing: .2em;
}

.tracking-\[0\.09em\] {
  letter-spacing: .09em;
}

.tracking-\[0\.12em\] {
  letter-spacing: .12em;
}

.tracking-\[0\.1em\] {
  letter-spacing: .1em;
}

.tracking-\[0\.25em\] {
  letter-spacing: .25em;
}

.tracking-\[0\.2em\] {
  letter-spacing: .2em;
}

.tracking-\[0\.3em\] {
  letter-spacing: .3em;
}

.tracking-\[0\.4em\] {
  letter-spacing: .4em;
}

.tracking-\[0\.5px\] {
  letter-spacing: .5px;
}

.tracking-\[2px\] {
  letter-spacing: 2px;
}

.tracking-\[4px\] {
  letter-spacing: 4px;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-\[\#808080\] {
  --tw-text-opacity: 1 !important;
  color: rgb(128 128 128 / var(--tw-text-opacity, 1)) !important;
}

.\!text-deep-blue {
  --tw-text-opacity: 1 !important;
  color: rgb(12 38 50 / var(--tw-text-opacity, 1)) !important;
}

.\!text-transparent {
  color: #0000 !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#002733\] {
  --tw-text-opacity: 1;
  color: rgb(0 39 51 / var(--tw-text-opacity, 1));
}

.text-\[\#003343\] {
  --tw-text-opacity: 1;
  color: rgb(0 51 67 / var(--tw-text-opacity, 1));
}

.text-\[\#0C2632\] {
  --tw-text-opacity: 1;
  color: rgb(12 38 50 / var(--tw-text-opacity, 1));
}

.text-\[\#43262D\] {
  --tw-text-opacity: 1;
  color: rgb(67 38 45 / var(--tw-text-opacity, 1));
}

.text-\[\#499d49\] {
  --tw-text-opacity: 1;
  color: rgb(73 157 73 / var(--tw-text-opacity, 1));
}

.text-\[\#5F3741\] {
  --tw-text-opacity: 1;
  color: rgb(95 55 65 / var(--tw-text-opacity, 1));
}

.text-\[\#736F62\] {
  --tw-text-opacity: 1;
  color: rgb(115 111 98 / var(--tw-text-opacity, 1));
}

.text-\[\#C3A2A1\] {
  --tw-text-opacity: 1;
  color: rgb(195 162 161 / var(--tw-text-opacity, 1));
}

.text-\[\#CECABA\] {
  --tw-text-opacity: 1;
  color: rgb(206 202 186 / var(--tw-text-opacity, 1));
}

.text-\[\#CFCAB8\] {
  --tw-text-opacity: 1;
  color: rgb(207 202 184 / var(--tw-text-opacity, 1));
}

.text-\[\#E8E3D2\] {
  --tw-text-opacity: 1;
  color: rgb(232 227 210 / var(--tw-text-opacity, 1));
}

.text-\[\#EA3324\] {
  --tw-text-opacity: 1;
  color: rgb(234 51 36 / var(--tw-text-opacity, 1));
}

.text-\[\#F7D152\] {
  --tw-text-opacity: 1;
  color: rgb(247 209 82 / var(--tw-text-opacity, 1));
}

.text-\[\#FCEFC6\] {
  --tw-text-opacity: 1;
  color: rgb(252 239 198 / var(--tw-text-opacity, 1));
}

.text-\[\#FFEEC1\] {
  --tw-text-opacity: 1;
  color: rgb(255 238 193 / var(--tw-text-opacity, 1));
}

.text-\[\#FFFEFB\] {
  --tw-text-opacity: 1;
  color: rgb(255 254 251 / var(--tw-text-opacity, 1));
}

.text-\[\#ea3324\] {
  --tw-text-opacity: 1;
  color: rgb(234 51 36 / var(--tw-text-opacity, 1));
}

.text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-dark-blue {
  --tw-text-opacity: 1;
  color: rgb(0 39 51 / var(--tw-text-opacity, 1));
}

.text-deep-blue {
  --tw-text-opacity: 1;
  color: rgb(12 38 50 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-40 {
  opacity: .4;
}

.opacity-60 {
  opacity: .6;
}

.opacity-80 {
  opacity: .8;
}

.outline-0 {
  outline-width: 0;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[4px_2px_35px_0px_rgba\(0\,0\,0\,0\.08\)\] {
  --tw-drop-shadow: drop-shadow(4px 2px 35px 0px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[4px_2px_35px_rgba\(0\,0\,0\,0\.25\)\] {
  --tw-drop-shadow: drop-shadow(4px 2px 35px #00000040);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@font-face {
  font-family: Cormorant-Italic;
  font-weight: 400;
  src: url("Cormorant-Italic-VariableFont_wght.a5e88363.ttf");
}

@font-face {
  font-family: PPRightGothic;
  src: url("PPRightGothic-Medium.92628248.otf");
}

@font-face {
  font-family: InterLight;
  src: url("Inter-Light.490626be.ttf");
}

@font-face {
  font-family: InterNormal;
  src: url("Inter-Regular.7cfebe77.ttf");
}

@font-face {
  font-family: InterMedium;
  src: url("Inter-Medium.775d466f.ttf");
}

@font-face {
  font-family: InterBold;
  src: url("Inter-Bold.88a04ef3.ttf");
}

@font-face {
  font-family: InterBlack;
  src: url("Inter-Black.4837b389.ttf");
}

b {
  font-family: InterMedium, system-ui, sans-serif;
}

.conv-tail-question {
  position: relative;
}

.conv-tail-question:before {
  content: url("data:image/svg+xml,%3Csvg width='46' height='14' viewBox='0 0 46 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.3571 7.96239C44.3567 6.96214 36.8567 0.962069 36.8567 0.962069C36.8567 0.962069 25.8571 -0.537423 23.3573 0.961902C12.8574 10.4629 0.856232 12.9628 0.856232 12.9628C0.856232 12.9628 23.8567 16.4621 45.3571 7.96239Z' fill='%23E9E3D0'/%3E%3C/svg%3E%0A");
  width: 46px;
  height: 14px;
  position: absolute;
  bottom: 1px;
  left: -14px;
}

.conv-tail-answer:before {
  content: url("data:image/svg+xml,%3Csvg width='45' height='14' viewBox='0 0 45 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.287444 7.96184C1.28783 6.96159 8.78783 0.961519 8.78783 0.961519C8.78783 0.961519 19.7874 -0.537972 22.2872 0.961353C32.7871 10.4623 44.7883 12.9623 44.7883 12.9623C44.7883 12.9623 21.7878 16.4616 0.287444 7.96184Z' fill='%230C2632'/%3E%3C/svg%3E");
  opacity: 0;
  width: 45px;
  height: 14px;
  animation: .15s ease-out .15s forwards itemsConvTail;
  position: absolute;
  bottom: 1px;
  right: -14px;
}

.conv-tail-answer_no-delay:before {
  animation-delay: 0s;
}

.items-conv {
  opacity: 0;
  animation: .3s ease-out forwards itemsConv;
}

.chat-widget-hidden {
  display: none !important;
}

@keyframes itemsConv {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes itemsConvTail {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.conv-list > div {
  margin-bottom: 16px;
}

.conv-list > div:first-of-type {
  margin-bottom: 0;
}

.swiper-pagination-bullet {
  background: none !important;
  border: 1px solid #736f62 !important;
}

.swiper-pagination-bullet-active {
  background: #273f5c !important;
}

.swiper-pagination-bullet--fraction {
  border-width: 0 !important;
}

.swiper-pagination-bullet--fraction.swiper-pagination-bullet-active {
  background-color: #0000 !important;
  border: 1px solid #0c2632 !important;
}

.swiper-pagination--reviews .swiper-pagination-bullet {
  opacity: 1;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid #273f5c !important;
}

.swiper-articles-filter .swiper-pagination {
  bottom: 80px !important;
}

@media (width >= 1024px) {
  .swiper-articles-filter .swiper-pagination {
    bottom: 85px !important;
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #cecaba !important;
}

.swiper-articles-filter .swiper-slide {
  margin-top: 0 !important;
}

.swiper-articles-seo .swiper-slide {
  height: auto !important;
}

.last-articles .article-grid-item {
  margin-bottom: 25px !important;
}

.is-sticky {
  z-index: 999;
  animation: .5s ease-in-out fadeInDown;
  position: fixed;
  top: 98px;
}

#preplan_preview canvas.react-pdf__Page__canvas {
  width: 100% !important;
}

.sticky-outer-wrapper {
  width: 100%;
}

.marker\:text-\[30px\] ::marker {
  font-size: 30px;
}

.marker\:leading-none ::marker {
  line-height: 1;
}

.marker\:text-\[30px\]::marker {
  font-size: 30px;
}

.marker\:leading-none::marker {
  line-height: 1;
}

.placeholder\:text-\[13px\]::placeholder {
  font-size: 13px;
}

.placeholder\:italic::placeholder {
  font-style: italic;
}

.placeholder\:text-\[\#736F62\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(115 111 98 / var(--tw-text-opacity, 1));
}

.placeholder\:text-\[\#CECABA\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(206 202 186 / var(--tw-text-opacity, 1));
}

.placeholder\:text-\[\#CFCAB8\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(207 202 184 / var(--tw-text-opacity, 1));
}

.placeholder\:text-\[\#FFFFFF80\]::placeholder {
  color: #ffffff80;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-left-6:before {
  content: var(--tw-content);
  left: -1.5rem;
}

.before\:-left-7:before {
  content: var(--tw-content);
  left: -1.75rem;
}

.before\:-left-8:before {
  content: var(--tw-content);
  left: -2rem;
}

.before\:-left-\[25px\]:before {
  content: var(--tw-content);
  left: -25px;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-1\.5:before {
  content: var(--tw-content);
  left: .375rem;
}

.before\:left-2\/4:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:left-\[-50rem\]:before {
  content: var(--tw-content);
  left: -50rem;
}

.before\:left-\[5px\]:before {
  content: var(--tw-content);
  left: 5px;
}

.before\:right-3:before {
  content: var(--tw-content);
  right: .75rem;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1:before {
  content: var(--tw-content);
  top: .25rem;
}

.before\:top-1\.5:before {
  content: var(--tw-content);
  top: .375rem;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:top-2:before {
  content: var(--tw-content);
  top: .5rem;
}

.before\:top-2\/4:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:z-20:before {
  content: var(--tw-content);
  z-index: 20;
}

.before\:z-30:before {
  content: var(--tw-content);
  z-index: 30;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-1\.5:before {
  content: var(--tw-content);
  height: .375rem;
}

.before\:h-2:before {
  content: var(--tw-content);
  height: .5rem;
}

.before\:h-6:before {
  content: var(--tw-content);
  height: 1.5rem;
}

.before\:h-\[10px\]:before {
  content: var(--tw-content);
  height: 10px;
}

.before\:h-\[110\%\]:before {
  content: var(--tw-content);
  height: 110%;
}

.before\:h-\[12px\]:before {
  content: var(--tw-content);
  height: 12px;
}

.before\:h-\[15px\]:before {
  content: var(--tw-content);
  height: 15px;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:w-2:before {
  content: var(--tw-content);
  width: .5rem;
}

.before\:w-3:before {
  content: var(--tw-content);
  width: .75rem;
}

.before\:w-6:before {
  content: var(--tw-content);
  width: 1.5rem;
}

.before\:w-\[12px\]:before {
  content: var(--tw-content);
  width: 12px;
}

.before\:w-\[15px\]:before {
  content: var(--tw-content);
  width: 15px;
}

.before\:w-\[1px\]:before {
  content: var(--tw-content);
  width: 1px;
}

.before\:w-\[2px\]:before {
  content: var(--tw-content);
  width: 2px;
}

.before\:w-\[500\%\]:before {
  content: var(--tw-content);
  width: 500%;
}

.before\:-translate-x-2\/4:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-2\/4:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:border-b:before {
  content: var(--tw-content);
  border-bottom-width: 1px;
}

.before\:border-b-2:before, .before\:border-b-\[2px\]:before {
  content: var(--tw-content);
  border-bottom-width: 2px;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-l-2:before, .before\:border-l-\[2px\]:before {
  content: var(--tw-content);
  border-left-width: 2px;
}

.before\:border-\[\#467655\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(70 118 85 / var(--tw-border-opacity, 1));
}

.before\:border-\[\#A0C1AB\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(160 193 171 / var(--tw-border-opacity, 1));
}

.before\:border-\[\#F7D152\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(247 209 82 / var(--tw-border-opacity, 1));
}

.before\:border-b-\[\#736F62\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(115 111 98 / var(--tw-border-opacity, 1));
}

.before\:border-b-\[\#fff\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.before\:border-l-\[\#736F62\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(115 111 98 / var(--tw-border-opacity, 1));
}

.before\:border-l-\[\#fff\]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.before\:bg-\[\#0C2632\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 50 / var(--tw-bg-opacity, 1));
}

.before\:bg-\[\#6F86A0\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(111 134 160 / var(--tw-bg-opacity, 1));
}

.before\:bg-\[\#CECABA\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(206 202 186 / var(--tw-bg-opacity, 1));
}

.before\:bg-\[\#CFCAB8\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(207 202 184 / var(--tw-bg-opacity, 1));
}

.before\:bg-neutral-yellow:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(243 240 230 / var(--tw-bg-opacity, 1));
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:transition-opacity:before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:-left-1\.5:after {
  content: var(--tw-content);
  left: -.375rem;
}

.after\:-left-8:after {
  content: var(--tw-content);
  left: -2rem;
}

.after\:-left-\[30px\]:after {
  content: var(--tw-content);
  left: -30px;
}

.after\:-left-\[35px\]:after {
  content: var(--tw-content);
  left: -35px;
}

.after\:-right-24:after {
  content: var(--tw-content);
  right: -6rem;
}

.after\:-top-1\.5:after {
  content: var(--tw-content);
  top: -.375rem;
}

.after\:-top-\[6px\]:after {
  content: var(--tw-content);
  top: -6px;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-2:after {
  content: var(--tw-content);
  left: .5rem;
}

.after\:left-3:after {
  content: var(--tw-content);
  left: .75rem;
}

.after\:right-4:after {
  content: var(--tw-content);
  right: 1rem;
}

.after\:right-5:after {
  content: var(--tw-content);
  right: 1.25rem;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-1\/3:after {
  content: var(--tw-content);
  top: 33.3333%;
}

.after\:top-2\.5:after {
  content: var(--tw-content);
  top: .625rem;
}

.after\:top-7:after {
  content: var(--tw-content);
  top: 1.75rem;
}

.after\:z-10:after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:z-20:after {
  content: var(--tw-content);
  z-index: 20;
}

.after\:ml-2\.5:after {
  content: var(--tw-content);
  margin-left: .625rem;
}

.after\:h-\[10px\]:after {
  content: var(--tw-content);
  height: 10px;
}

.after\:h-\[11px\]:after {
  content: var(--tw-content);
  height: 11px;
}

.after\:h-\[24px\]:after {
  content: var(--tw-content);
  height: 24px;
}

.after\:h-\[25px\]:after {
  content: var(--tw-content);
  height: 25px;
}

.after\:h-\[2px\]:after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-\[86px\]:after {
  content: var(--tw-content);
  height: 86px;
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:w-\[10px\]:after {
  content: var(--tw-content);
  width: 10px;
}

.after\:w-\[11px\]:after {
  content: var(--tw-content);
  width: 11px;
}

.after\:w-\[15px\]:after {
  content: var(--tw-content);
  width: 15px;
}

.after\:w-\[174px\]:after {
  content: var(--tw-content);
  width: 174px;
}

.after\:w-\[24px\]:after {
  content: var(--tw-content);
  width: 24px;
}

.after\:w-\[25px\]:after {
  content: var(--tw-content);
  width: 25px;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:origin-top-left:after {
  content: var(--tw-content);
  transform-origin: 0 0;
}

.after\:-rotate-90:after {
  content: var(--tw-content);
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-225:after {
  content: var(--tw-content);
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-\[-130deg\]:after {
  content: var(--tw-content);
  --tw-rotate: -130deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:transform:after {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border-b:after {
  content: var(--tw-content);
  border-bottom-width: 1px;
}

.after\:border-b-\[2px\]:after {
  content: var(--tw-content);
  border-bottom-width: 2px;
}

.after\:border-l:after {
  content: var(--tw-content);
  border-left-width: 1px;
}

.after\:border-r:after {
  content: var(--tw-content);
  border-right-width: 1px;
}

.after\:border-\[\#F7D152\]:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(247 209 82 / var(--tw-border-opacity, 1));
}

.after\:border-\[\#fff\]:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.after\:border-black\/70:after {
  content: var(--tw-content);
  border-color: #000000b3;
}

.after\:bg-\[\#E8E3D2\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(232 227 210 / var(--tw-bg-opacity, 1));
}

.after\:bg-\[\#fff\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.after\:bg-gradient-to-b:after {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.after\:from-\[\#FFFFFF00\]:after {
  content: var(--tw-content);
  --tw-gradient-from: #fff0 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-white:after {
  content: var(--tw-content);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.after\:shadow-\[inset_-5px_-25rem_35px_-20px_\#fff\]:after {
  content: var(--tw-content);
  --tw-shadow: inset -5px -25rem 35px -20px #fff;
  --tw-shadow-colored: inset -5px -25rem 35px -20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\|\'\]:after {
  --tw-content: "|";
  content: var(--tw-content);
}

.last\:before\:bg-\[\#273F5C\]:last-child:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(39 63 92 / var(--tw-bg-opacity, 1));
}

.checked\:border-\[\#002733\]:checked {
  --tw-border-opacity: 1;
  border-color: rgb(0 39 51 / var(--tw-border-opacity, 1));
}

.checked\:border-\[\#CFCAB8\]:checked {
  --tw-border-opacity: 1;
  border-color: rgb(207 202 184 / var(--tw-border-opacity, 1));
}

.checked\:\!bg-\[\#6F86A0\]:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(111 134 160 / var(--tw-bg-opacity, 1)) !important;
}

.checked\:before\:bg-\[\#002733\]:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 39 51 / var(--tw-bg-opacity, 1));
}

.checked\:before\:bg-\[\#CFCAB8\]:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(207 202 184 / var(--tw-bg-opacity, 1));
}

.read-only\:bg-\[\#f6f6f6\]:read-only {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity, 1));
}

.hover\:border-b:hover {
  border-bottom-width: 1px;
}

.hover\:\!border-\[\#6F86A0\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(111 134 160 / var(--tw-border-opacity, 1)) !important;
}

.hover\:\!border-\[\#CECABA\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(206 202 186 / var(--tw-border-opacity, 1)) !important;
}

.hover\:border-\[\#43262D\]\/\[\.7\]:hover {
  border-color: #43262db3;
}

.hover\:border-\[\#43262db8\]:hover {
  border-color: #43262db8;
}

.hover\:border-\[\#6F86A0\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(111 134 160 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#CECABA\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(206 202 186 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#fff\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.hover\:border-b-deep-blue:hover {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(12 38 50 / var(--tw-border-opacity, 1));
}

.hover\:border-b-transparent:hover {
  border-bottom-color: #0000;
}

.hover\:\!bg-\[\#0C2632\]\/10:hover {
  background-color: #0c26321a !important;
}

.hover\:\!bg-\[\#6F86A0\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(111 134 160 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-\[\#0C2632\]\/10:hover {
  background-color: #0c26321a;
}

.hover\:bg-\[\#43262D\]\/\[\.7\]:hover {
  background-color: #43262db3;
}

.hover\:bg-\[\#43262db8\]:hover {
  background-color: #43262db8;
}

.hover\:bg-\[\#6F86A0\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(111 134 160 / var(--tw-bg-opacity, 1));
}

.hover\:bg-deep-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 50 / var(--tw-bg-opacity, 1));
}

.hover\:text-\[\#6F86A0\]:hover {
  --tw-text-opacity: 1;
  color: rgb(111 134 160 / var(--tw-text-opacity, 1));
}

.hover\:text-\[\#CECABA\]:hover {
  --tw-text-opacity: 1;
  color: rgb(206 202 186 / var(--tw-text-opacity, 1));
}

.hover\:text-\[\#fff\]:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:text-deep-blue:hover {
  --tw-text-opacity: 1;
  color: rgb(12 38 50 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:before\:opacity-10:hover:before {
  content: var(--tw-content);
  opacity: .1;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-\[\#0C2632\]:focus {
  outline-color: #0c2632;
}

.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-\[\#cfcab86e\]:focus-visible {
  --tw-ring-color: #cfcab86e;
}

.group:hover .group-hover\:opacity-80 {
  opacity: .8;
}

.peer:checked ~ .peer-checked\:opacity-100 {
  opacity: 1;
}

@media (width >= 640px) {
  .sm\:max-w-\[640px\] {
    max-width: 640px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .md\:-mr-0 {
    margin-right: 0;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:max-w-\[700px\] {
    max-width: 700px;
  }

  .md\:max-w-\[768px\] {
    max-width: 768px;
  }

  .md\:shrink {
    flex-shrink: 1;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:leading-\[52\.80px\] {
    line-height: 52.8px;
  }
}

@media (width >= 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:\!left-10 {
    left: 2.5rem !important;
  }

  .lg\:\!right-10 {
    right: 2.5rem !important;
  }

  .lg\:\!top-\[45\%\] {
    top: 45% !important;
  }

  .lg\:-bottom-\[25px\] {
    bottom: -25px;
  }

  .lg\:-left-20 {
    left: -5rem;
  }

  .lg\:-left-24 {
    left: -6rem;
  }

  .lg\:-left-3 {
    left: -.75rem;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:left-11 {
    left: 2.75rem;
  }

  .lg\:left-\[25px\] {
    left: 25px;
  }

  .lg\:top-10 {
    top: 2.5rem;
  }

  .lg\:top-12 {
    top: 3rem;
  }

  .lg\:top-2\.5 {
    top: .625rem;
  }

  .lg\:top-9 {
    top: 2.25rem;
  }

  .lg\:top-\[-17px\] {
    top: -17px;
  }

  .lg\:top-\[25px\] {
    top: 25px;
  }

  .lg\:top-\[340px\] {
    top: 340px;
  }

  .lg\:top-\[50\%\] {
    top: 50%;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:ml-\[190px\] {
    margin-left: 190px;
  }

  .lg\:ml-\[30px\] {
    margin-left: 30px;
  }

  .lg\:ml-\[75px\] {
    margin-left: 75px;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mt-\[200px\] {
    margin-top: 200px;
  }

  .lg\:mt-\[25px\] {
    margin-top: 25px;
  }

  .lg\:mt-\[355px\] {
    margin-top: 355px;
  }

  .lg\:mt-\[75px\] {
    margin-top: 75px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[118px\] {
    height: 118px;
  }

  .lg\:h-\[120px\] {
    height: 120px;
  }

  .lg\:h-\[150px\] {
    height: 150px;
  }

  .lg\:h-\[590px\] {
    height: 590px;
  }

  .lg\:h-\[720px\] {
    height: 720px;
  }

  .lg\:h-\[735px\] {
    height: 735px;
  }

  .lg\:h-\[75px\] {
    height: 75px;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:max-h-\[450px\] {
    max-height: 450px;
  }

  .lg\:max-h-\[480px\] {
    max-height: 480px;
  }

  .lg\:max-h-\[50px\] {
    max-height: 50px;
  }

  .lg\:max-h-\[520px\] {
    max-height: 520px;
  }

  .lg\:max-h-\[545px\] {
    max-height: 545px;
  }

  .lg\:max-h-\[590px\] {
    max-height: 590px;
  }

  .lg\:max-h-\[640px\] {
    max-height: 640px;
  }

  .lg\:max-h-\[655px\] {
    max-height: 655px;
  }

  .lg\:max-h-\[65vh\] {
    max-height: 65vh;
  }

  .lg\:max-h-\[70vh\] {
    max-height: 70vh;
  }

  .lg\:max-h-\[calc\(590px_-_30px_-_142px\)\] {
    max-height: 418px;
  }

  .lg\:max-h-\[calc\(70vh_-_150px\)\] {
    max-height: calc(70vh - 150px);
  }

  .lg\:max-h-fit {
    max-height: fit-content;
  }

  .lg\:min-h-\[465px\] {
    min-height: 465px;
  }

  .lg\:min-h-\[490px\] {
    min-height: 490px;
  }

  .lg\:min-h-\[52px\] {
    min-height: 52px;
  }

  .lg\:min-h-\[610px\] {
    min-height: 610px;
  }

  .lg\:min-h-\[640px\] {
    min-height: 640px;
  }

  .lg\:min-h-\[70vh\] {
    min-height: 70vh;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-\[118px\] {
    width: 118px;
  }

  .lg\:w-\[150px\] {
    width: 150px;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:w-\[220px\] {
    width: 220px;
  }

  .lg\:w-\[250px\] {
    width: 250px;
  }

  .lg\:w-\[260px\] {
    width: 260px;
  }

  .lg\:w-\[27px\] {
    width: 27px;
  }

  .lg\:w-\[340px\] {
    width: 340px;
  }

  .lg\:w-\[43\%\] {
    width: 43%;
  }

  .lg\:w-\[500px\] {
    width: 500px;
  }

  .lg\:w-\[57\%\] {
    width: 57%;
  }

  .lg\:w-\[585px\] {
    width: 585px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-\[175px\] {
    min-width: 175px;
  }

  .lg\:min-w-\[730px\] {
    min-width: 730px;
  }

  .lg\:min-w-\[750px\] {
    min-width: 750px;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-\[100\%\] {
    max-width: 100%;
  }

  .lg\:max-w-\[1000px\] {
    max-width: 1000px;
  }

  .lg\:max-w-\[1024px\] {
    max-width: 1024px;
  }

  .lg\:max-w-\[1320px\] {
    max-width: 1320px;
  }

  .lg\:max-w-\[135px\] {
    max-width: 135px;
  }

  .lg\:max-w-\[205px\] {
    max-width: 205px;
  }

  .lg\:max-w-\[230px\] {
    max-width: 230px;
  }

  .lg\:max-w-\[240px\] {
    max-width: 240px;
  }

  .lg\:max-w-\[256px\] {
    max-width: 256px;
  }

  .lg\:max-w-\[32\%\] {
    max-width: 32%;
  }

  .lg\:max-w-\[324px\] {
    max-width: 324px;
  }

  .lg\:max-w-\[325px\] {
    max-width: 325px;
  }

  .lg\:max-w-\[365px\] {
    max-width: 365px;
  }

  .lg\:max-w-\[480px\] {
    max-width: 480px;
  }

  .lg\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .lg\:max-w-\[535px\] {
    max-width: 535px;
  }

  .lg\:max-w-\[585px\] {
    max-width: 585px;
  }

  .lg\:max-w-\[605px\] {
    max-width: 605px;
  }

  .lg\:max-w-\[645px\] {
    max-width: 645px;
  }

  .lg\:max-w-\[70\%\] {
    max-width: 70%;
  }

  .lg\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .lg\:max-w-fit {
    max-width: fit-content;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scroll-my-20 {
    scroll-margin-top: 5rem;
    scroll-margin-bottom: 5rem;
  }

  .lg\:scroll-my-40 {
    scroll-margin-top: 10rem;
    scroll-margin-bottom: 10rem;
  }

  .lg\:scroll-mt-20 {
    scroll-margin-top: 5rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-1 {
    gap: .25rem;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-28 {
    gap: 7rem;
  }

  .lg\:gap-3 {
    gap: .75rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-x-3 {
    column-gap: .75rem;
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-r-\[8px\] {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .lg\:rounded-t-\[0\] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-br-\[24px\] {
    border-bottom-right-radius: 24px;
  }

  .lg\:rounded-tr-\[0\] {
    border-top-right-radius: 0;
  }

  .lg\:border-\[1px\] {
    border-width: 1px;
  }

  .lg\:border-\[2px\] {
    border-width: 2px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-\[\#CECABA\] {
    --tw-border-opacity: 1;
    border-color: rgb(206 202 186 / var(--tw-border-opacity, 1));
  }

  .lg\:border-\[\#CFCAB8\] {
    --tw-border-opacity: 1;
    border-color: rgb(207 202 184 / var(--tw-border-opacity, 1));
  }

  .lg\:border-dark-blue {
    --tw-border-opacity: 1;
    border-color: rgb(0 39 51 / var(--tw-border-opacity, 1));
  }

  .lg\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:bg-\[length\:156px_130px\] {
    background-size: 156px 130px;
  }

  .lg\:object-\[0_84px\] {
    object-position: 0 84px;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:px-\[23px\] {
    padding-left: 23px;
    padding-right: 23px;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .lg\:py-\[14px\] {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .lg\:py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .lg\:py-\[16px\] {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .lg\:py-\[26px\] {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .lg\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-\[20px\] {
    padding-bottom: 20px;
  }

  .lg\:pl-28 {
    padding-left: 7rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pl-\[40px\] {
    padding-left: 40px;
  }

  .lg\:pl-\[42px\] {
    padding-left: 42px;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pr-\[32px\] {
    padding-right: 32px;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pt-9 {
    padding-top: 2.25rem;
  }

  .lg\:pt-\[74px\] {
    padding-top: 74px;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-\[12px\] {
    font-size: 12px;
  }

  .lg\:text-\[14px\] {
    font-size: 14px;
  }

  .lg\:text-\[16px\] {
    font-size: 16px;
  }

  .lg\:text-\[18px\] {
    font-size: 18px;
  }

  .lg\:text-\[20px\] {
    font-size: 20px;
  }

  .lg\:text-\[22px\] {
    font-size: 22px;
  }

  .lg\:text-\[24px\] {
    font-size: 24px;
  }

  .lg\:text-\[26px\] {
    font-size: 26px;
  }

  .lg\:text-\[36px\] {
    font-size: 36px;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }

  .lg\:text-\[42px\] {
    font-size: 42px;
  }

  .lg\:text-\[44px\] {
    font-size: 44px;
  }

  .lg\:text-\[45px\] {
    font-size: 45px;
  }

  .lg\:text-\[48px\] {
    font-size: 48px;
  }

  .lg\:text-\[50px\] {
    font-size: 50px;
  }

  .lg\:text-\[64px\] {
    font-size: 64px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-\[1\.1\] {
    line-height: 1.1;
  }

  .lg\:leading-\[40px\] {
    line-height: 40px;
  }

  .lg\:leading-\[42px\] {
    line-height: 42px;
  }

  .lg\:leading-\[50\.4px\] {
    line-height: 50.4px;
  }

  .lg\:leading-\[50px\] {
    line-height: 50px;
  }

  .lg\:leading-\[52\.80px\] {
    line-height: 52.8px;
  }

  .lg\:leading-\[53px\] {
    line-height: 53px;
  }

  .lg\:leading-\[54px\] {
    line-height: 54px;
  }

  .lg\:leading-\[70px\] {
    line-height: 70px;
  }

  .lg\:leading-\[74px\] {
    line-height: 74px;
  }

  .lg\:leading-none {
    line-height: 1;
  }

  .lg\:tracking-\[2px\] {
    letter-spacing: 2px;
  }

  .lg\:text-dark-blue {
    --tw-text-opacity: 1;
    color: rgb(0 39 51 / var(--tw-text-opacity, 1));
  }

  .lg\:text-deep-blue {
    --tw-text-opacity: 1;
    color: rgb(12 38 50 / var(--tw-text-opacity, 1));
  }

  .lg\:after\:border-\[\#467655\]:after {
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(70 118 85 / var(--tw-border-opacity, 1));
  }

  .after\:lg\:shadow-\[inset_-5px_-43rem_35px_-20px_\#fff\]:after {
    content: var(--tw-content);
    --tw-shadow: inset -5px -43rem 35px -20px #fff;
    --tw-shadow-colored: inset -5px -43rem 35px -20px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1280px) {
  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mt-9 {
    margin-top: 2.25rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:max-w-\[1000px\] {
    max-width: 1000px;
  }

  .xl\:max-w-\[1360px\] {
    max-width: 1360px;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:text-\[36px\] {
    font-size: 36px;
  }

  .xl\:text-\[48px\] {
    font-size: 48px;
  }

  .xl\:text-\[64px\] {
    font-size: 64px;
  }

  .xl\:leading-\[4\.4rem\] {
    line-height: 4.4rem;
  }

  .xl\:leading-\[4\.5rem\] {
    line-height: 4.5rem;
  }

  .xl\:leading-\[40px\] {
    line-height: 40px;
  }

  .xl\:leading-\[74px\] {
    line-height: 74px;
  }
}

@media (width >= 2560px) {
  .\32 k\:max-h-\[1000px\] {
    max-height: 1000px;
  }

  .\32 k\:min-h-\[800px\] {
    min-height: 800px;
  }

  .\32 k\:max-w-\[2460px\] {
    max-width: 2460px;
  }
}

@media (width >= 3840px) {
  .\34 k\:max-h-\[1200px\] {
    max-height: 1200px;
  }

  .\34 k\:min-h-\[1200px\] {
    min-height: 1200px;
  }

  .\34 k\:max-w-\[3760px\] {
    max-width: 3760px;
  }
}
/*# sourceMappingURL=index.acadde24.css.map */
